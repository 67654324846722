<template>
  <div class="viewUser">
    <b-card class="m-2 mt-4" :body-class="'card-padding'" v-if="isUserFilter">
      <img class="mt-3 close-filter-icon" src="../../assets/CloseModel.svg" width="15px" style="cursor:pointer" @click="openFilter">
      <div class="row">
        <div class="col-md-3 input-user">
          <label class="tree-label">Search</label>
          <w-text-input
            :fieldType="'text'"
            :disabled="false"
            :defaultValue="userName"
            :labelStyle="'register-label'"
            :validation="{ alpha_num: true }"
            :fieldName="'Name'"
            :placeholder="'Search by name...'"
            @textInputChange="onChangeUserName($event)"
          />
        </div>
        <!-- <div class="col-md-3">
          <label class=" tree-label">Status</label>
          <w-dropdown
            :label-text="''"
            :selectedValue="null"
            :selectOption="statusListOptions"
            @selectedOption="onSelectedUserStatus($event)"
          />
        </div> -->
        <!-- <div class="col-md-3">
          <label class="tree-label">Role</label>
          <TreeDropdown
            :rootOptions="roleOptions"
            :selectedValue="roleSelected"
            @selectedField="getSelectedRoles($event)"
          />
        </div> -->
        <!-- <div class="col-md-3 input-user">
          <label class="tree-label">Manager</label>
          <w-text-input
            :fieldType="'text'"
            :disabled="false"
            :defaultValue="managerValue"
            :labelStyle="'register-label'"
            :validation="{ alpha_num: true }"
            :fieldName="'Manager'"
            @textInputChange="getManagerValue($event)"
          />
        </div> -->
      </div>
    </b-card>

    <w-table
      :tableLoading="tableLoading"
      :tableProperties="tabledata"
      :showUserSearchFilter="true"
      @getSelectedPageEvent="getSelectedPage($event)"
      @getSelectedPerPageEvent="getSelectedPerPage($event)"
      @deleteRowDataEvent="openConfirmationModal($event)"
      @editRowDataEvent="editUserDetails($event)"
      @openRoleModalEvent="openRoleModal($event)"
      @openDesktop_filterEvent="openFilter"
      :isFilterButton="true"
    />

    <w-delete-confirm-modal
      :msg="deleteUserName"
      @deleteData="deleteUserDetail($event)"
    ></w-delete-confirm-modal>
    <!-- error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
    <div>
      <!-- Modal for add/ change role start -->
      <b-modal id="changeRole-modal" title="Roles" centered>
        <!-- <p class="adduser_label">Roles</p> -->
        <div class="ml-3 d-flex">
          <div>
            <div class="user-icon">
              {{userLogo}}
            </div>
          </div>
          <div>
            <span class="ml-3 user-name">{{ firstNameOfAddRole }} {{ lastNameOfAddRole }}</span>
            <p class="ml-3 user-email">{{emailOfAddRole}}</p>
          </div>
        </div>
        <!-- <div class="my-4 mx-3">
          <p>Select Roles</p>
          <div class="p-3 modal-body border">
            <div>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="checkRoleList"
                :options="roleOptionList"
                @change="onChangeAddRole($event)"
              ></b-form-checkbox-group>
            </div>
          </div>
        </div> -->
        <div class="row p-0 m-0">
          <div class="col-lg-12">
            <label class="p-0 adduser_label"
              >Assigned Roles <!--<span class="warning_icon">!</span>-->
            </label>
            <div>
              <label v-for="(role,index) in assignedRole" :key="index" :id="`role${index}`" class="selectedRole-wrapper active" @click="unSelectRole(role.value,index)">
                <span style="display:inline-flex">
                  {{role.text}} 
                  <img src="../../assets/delete-red.svg" class="ml-3  delete-icon" width="11px">
                </span>
              </label>
            </div>
          </div>
          <div class="col-lg-12">
            <label class="p-0 adduser_label"
              >Other Roles <!--<span class="warning_icon">!</span>-->
            </label>
            <div>
              <span v-if="otherRole.length>0">
                <label v-for="(role,index) in otherRole" :key="index" :id="`role${index}`" class="role-wrapper" @click="selectRole(role.value,index)">
                  <span style="display:inline-flex">
                    {{role.text}}
                    <img src="../../assets/SVG/check.svg" class="ml-3  check-icon" width="11px">
                  </span>
                </label>
              </span>
              <span v-else>
                No more roles
              </span>
            </div>
          </div>
        </div>
        <div class="footer-btn text-right mb-3 mr-3">
          <!-- <span class="cancel-btn mr-3" @click="hidemodal">Cancel</span>
          <span class="submit-btn" @click="onSubmitAddRole">Submit</span> -->
          <w-button-input :label="'Save'" :buttonStyle="'generate-btn'" :buttonClass="'generate-btn role-btn'" class="mt-3" @buttonClicked="onSubmitAddRole()" />
          <w-button-input :label="'Cancel'" :buttonStyle="'request-demo-style'" class="ml-3 mt-3" :buttonClass="'cancel-button role-btn'" @buttonClicked="hidemodal()" />
        </div>
      </b-modal>
      <!-- Modal for add/ change role start -->
    </div>
  </div>
</template>

<script>
import TreeDropdown from "../../widgets/TreeDropdown";
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import Modal from "../../widgets/ModalWidget";
import DeleteConfirmModal from "../../widgets/DeleteConfirmModal.vue";
import {
  // getUserStatus,
  getUserRoles,
  getUserDetailsByFilter,
  deleteUserDetail,
  updateUserDetail,
} from "../../Service/UserService";
import ButtonInput from "../../widgets/InputButton.vue";


export default {
  props:["isGetData"],
  components: {
    TreeDropdown,
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    "w-table": TableWithCheckbox,
    "w-delete-confirm-modal": DeleteConfirmModal,
    Modal,
    "w-button-input":ButtonInput
  },
  data() {
    return {
      // for error modal
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      tableLoading: false,

      // for role modal checkbox
      firstNameOfAddRole: "",
      lastNameOfAddRole: "",
      emailOfAddRole: "",
      userID: "",
      userPassword: "",
      mobileNumber: "",
      userLogo:"",
      checkRoleList: [],  // for add/change role modal
      roleOptionList: [],  // for add/change role modal
      //

      // selectedStatusListValue: "",
      // statusListOptions: [],
      // managerValue: "",
      userName: "",
      // roleOptions: [], // role dropdown
      // roleSelected: [], // role dropdown

      deleteUserName: "",
      deleteUserId: "",
      options: [
        { value: "2", text: "2" },
        { value: "5", text: "5" },
        { value: "10", text: "10" },
        { value: "15", text: "15" },
        { value: "20", text: "20" },
      ],
      tabledata: {
        fields: [
          {
            key: "firstName",
            label: "First Name",
          },
          {
            key: "lastName",
            label: "Last Name",
          },
          {
            key: "mobileNumber",
            label: "Contact",
          },
          {
            key: "email",
            label: "Email ID",
          },
          // {
          //   key: "user_role",
          //   label: "Roles",
          // },
          // {
          //   key: "check_active_user",
          //   label: "Status",
          // },
          {
            key: "add_change_user",
            label: "Roles",
          },
          {
            key: "actionn_user_field",
            label: "Action",
          },
        ],

        items: [],

        perPage: 50,
        currentPage: 0,
        totalRows: 3,
      },
      isUserFilter:false,
      assignedRole:[],
      otherRole:[]
    };
  },
  created() {
    // // this.getUserStatus();
    // this.getUserRoles();
    // this.getUsersDetails();
    if(this.isGetData){
      this.getData();
    }
  },
  methods: {
    getData(){
      this.getUserRoles();
      this.getUsersDetails();
    },

    getUsersDetails() {
      this.tableLoading = true;
      getUserDetailsByFilter(
        this.userName,
        // this.selectedStatusListValue,
        // this.roleSelected // role dropdown
      )
        .then((res) => {
          if (res) {
            this.tableLoading = false;
            this.tabledata.items = [];
            this.tabledata.items = res || [];
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    // getUserStatus() {
    //   this.statusListOptions = [];
    //   var status = [];
    //   this.statusListOptions.push({
    //     value: null,
    //     text: "Select Status",
    //   });
    //   getUserStatus()
    //     .then((res) => {
    //       status = res;
    //       status.map((element) => {
    //         this.statusListOptions.push({
    //           value: element,
    //           text: element,
    //         });
    //       });
    //     })
    //     .catch((err) => {
    //       this.alert = true;
    //       this.alertMsg = err.message;
    //       this.alertType = "Failure";
    //       this.isConfirmAlert = false;
    //     });
    // },

    getUserRoles() {
      getUserRoles()
        .then((res) => {
          if (res) {
            var roles = [];
            roles = res || [];

            // role dropdown
            // roles.forEach((element) => {
            //   this.roleOptions.push({
            //     id: element.roleName,
            //     label: element.roleDisplayName,
            //   });
            // });

            // for change or add role
            roles.forEach((ele) => {
              this.roleOptionList.push({
                text: ele.roleDisplayName,
                value: ele.roleName,
                checked: false,
              });
            });
          }
        })
        .catch((err) => {
          // this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    deleteUserDetail(isDelete) {
      if (isDelete) {
        deleteUserDetail(this.deleteUserId).then((res) => {
          this.getUsersDetails();
        });
      }
      this.$bvModal.hide("delete-confirm-pop-modal");
    },

    // onSelectedUserStatus(event) {
    //   this.selectedStatusListValue = event;
    //   this.getUsersDetails();
    // },

    // getSelectedRoles(event) {
    //   this.roleSelected = event; // role dropdown
    //   this.getUsersDetails();
    // },
    onChangeUserName(event) {
      this.userName = event;
      this.getUsersDetails();
    },

    getIcon(iconName) {
      var images = require.context("../../assets/", false, /\.svg$/);
      return images("./" + iconName + ".svg");
    },

    openConfirmationModal(data) {
      console.log("data data :=> ", data);
      this.$bvModal.show("delete-confirm-pop-modal");
      this.deleteUserName = data.firstName;
      this.deleteUserId = data.id;
    },
    // close error modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // for edit user url
    editUserDetails(data) {
      this.$router.push({ name: "Edit User", params: { userData: data } });
    },
    // open add or change role modal
    openRoleModal(data) {
      console.log("user => ", data.item);
      this.firstNameOfAddRole = data.item.firstName;
      this.userLogo = `${data.item.firstName[0]}${data.item.lastName[0]}`
      this.lastNameOfAddRole = data.item.lastName;
      this.emailOfAddRole = data.item.email;
      this.userID = data.item.id;
      this.userPassword = data.item.password;
      this.mobileNumber = data.item.mobileNumber;

      this.checkRoleList = [];
      this.assignedRole = [];
      this.otherRole = [];
      let rolesList = [];
      
      data.item.roles.forEach((el) => {
        rolesList.push(el.roleDisplayName);
      });
      this.roleOptionList.map((role,index)=>{
        if(rolesList.indexOf(role.text) > -1){
          this.assignedRole.push(role);
          this.checkRoleList.push(role.value);
        }else{
          this.otherRole.push(role);
        }
      })
      console.log(data, " roleList",this.roleOptionList)
      // this.roleOptionList.map((ele,id) => {
      //   rolesList.find((el) => {
      //     if (el == ele.text) {
      //       this.selectRole(ele.id,id);
      //       console.log(ele, " elemenet")
      //       ele.checked = true;
      //       // this.checkRoleList.push(ele.value);
      //     } else {
      //       ele.checked = false;
      //     }
      //   });
        // if (roleSelected != -1) {
        //   ele.checked = true;
        // } else {
        //   ele.checked = false;
        // }
      // });
      this.$bvModal.show("changeRole-modal");
    },
    unSelectRole(id,index){
      console.log(id, index, " selected roloe")
      this.otherRole.push(this.assignedRole[index]);
      this.assignedRole.splice(index,1);
      this.checkRoleList.splice(index,1);
      console.log(this.checkRoleList, " checkRoleLsit ")
      // if(this.checkRoleList.indexOf(id) !== -1){
      //   this.checkRoleList.splice(this.checkRoleList.indexOf(id),1);
      //   console.log(this.checkRoleList, " roleselectesd if",this.checkRoleList.indexOf(id))
      //   // document.getElementById(`role${index}`)
      //   // $(`#role${index}`).removeClass('active')
      // }else{
      //   this.checkRoleList.push(id);
      //   console.log(this.checkRoleList, " roleselectesd else",this.checkRoleList.indexOf(id))
      //   // $(`#role${index}`).addClass('active')
      // }
    },
    selectRole(id,index){
      console.log(id, index, " selected roloe")
      this.assignedRole.push(this.otherRole[index])
      this.checkRoleList.push(this.otherRole[index].value);
      this.otherRole.splice(index,1);
      console.log(this.checkRoleList, " checkRoleLsit ")
      // if(this.checkRoleList.indexOf(id) !== -1){
      //   this.checkRoleList.splice(this.checkRoleList.indexOf(id),1);
      //   console.log(this.checkRoleList, " roleselectesd if",this.checkRoleList.indexOf(id))
      //   // document.getElementById(`role${index}`)
      //   // $(`#role${index}`).removeClass('active')
      // }else{
      //   this.checkRoleList.push(id);
      //   console.log(this.checkRoleList, " roleselectesd else",this.checkRoleList.indexOf(id))
      //   // $(`#role${index}`).addClass('active')
      // }
    },
    openFilter(){
      this.isUserFilter = !this.isUserFilter
    },
    // for role modal checkbox
    onChangeAddRole(event) {
      this.selectedAddRoleList = event;
      console.log("add role changes => ", this.selectedAddRoleList);
    },

    // submit after adding or change role
    onSubmitAddRole() {
      let changeRole = {
        firstName: this.firstNameOfAddRole,
        lastName: this.lastNameOfAddRole,
        email: this.emailOfAddRole,
        id: this.userID,
        password: this.userPassword,
        mobileNumber: this.mobileNumber,
        roleNames: this.checkRoleList,
      };

      // console.log("change role => ", addRole);
      updateUserDetail(changeRole)
        .then((res) => {
          console.log("added roles", res);
          this.getUsersDetails();
          setTimeout(this.$bvModal.hide("changeRole-modal"), 3000);
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    hidemodal() {
      this.$bvModal.hide("changeRole-modal");
    },
  },
};
</script>

<style scoped>
/* .card-padding {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0 !important;
} */
.viewUser .filteruser .filter {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #1b1c1d;
  font-weight: 600;
}
.viewUser .filteruser label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #767676;
}
.viewUser .input-user ::v-deep .form-control {
  height: 35px !important;
  opacity: 0.7;
}
.pagination_section ul {
  margin-bottom: 0;
}
/* .user_table .adduser_btn {
  border: solid 1px #5fa6e7;
  border-radius: 2px;
  color: #2e8cd3;
  font-size: 13px;
  padding: 2px 10px;
  background-color: #fff;
} */
/* .user_table .adduser_btn:hover {
  background: #fff;
} */
.user_table .userIcon {
  width: 18px;
  height: 10px;
  object-fit: contain;
  display: inline-block;
  margin: 3px;
}
.delete_user {
  color: #1b1c1d;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.edit {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.resend {
  width: 15.1px;
  height: 20px;
  object-fit: contain;
  margin-left: 8px;
}
.resendd {
  width: 38px;
  height: 16px;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;

  color: #2879b6;
}
.delete {
  width: 20px;
  height: 20px;
  margin-left: 4px;
}
img {
  margin: 0;
}
.confirm_delete,
.cancel_delete {
  font-size: 14px;
  padding: 4px 30px;
  color: #2e8cd3;
  background-color: #fff;
  border-color: #2e8cd3;
}
.confirm_delete:hover,
.cancel_delete:hover {
  background-color: #2e8cd3;
  color: #fff;
}
.pagination_section .show-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #222838;
}

.viewUser .filteruser ::v-deep .card-body {
  padding: 14px !important;
}
.viewUser .user_table ::v-deep .card-body {
  padding-top: 0 !important;
}
.viewUser ::v-deep table thead tr th {
  color: #1b1c1d;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  text-align: center !important;
}
.viewUser ::v-deep table tbody td {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  color: #1b1c1d;
  text-align: center;
}
.viewUser ::v-deep table thead tr th:first-child {
  text-align: left !important;
}
.viewUser ::v-deep table tbody tr td:first-child {
  text-align: left !important;
}
.viewUser ::v-deep #app {
  display: flex;
}

.cancel-btn,
.submit-btn {
  border: 1px solid #2e8cd3;
  padding: 4px 15px;
  color: #2e8cd3;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}
.delete-icon{
  display:none;
  filter: brightness(0) invert(1);
}
.selectedRole-wrapper{
  padding: .5rem .8rem;
  border: 1px solid #023449;
  border-radius: 5px;
  cursor: pointer;
  color:#023449;
  transition-duration: .3s;
  font-size: 14px;
  margin-right: .5rem;
}
.selectedRole-wrapper.active{
  background-color: #023449;
  color:#FFFFFF;
  /* width: 205px; */
  transition: 0.1s linear;
}
.selectedRole-wrapper.active:hover{
  background-color: #DE383A !important;
  border: 1px solid #DE383A;
  /* width: 210px; */
}
.selectedRole-wrapper:hover .delete-icon{
  display: block;
}
.check-icon{
  display:none;
  filter: brightness(0) invert(1);
}
.role-wrapper{
  padding: .5rem .8rem;
  border: 1px solid #023449;
  border-radius: 5px;
  cursor: pointer;
  color:#023449;
  transition-duration: .3s;
  font-size: 14px;
  margin-right: .5rem;
}
.role-wrapper:hover .check-icon{
  display: block;
}

.role-wrapper:hover{
  background-color: #023449;
  color:#FFFFFF;
}
.submit-btn {
  background: #2e8cd3;
  color: #fff;
}
.adduser_label{
  color: #1B1C1D;
  font-size: 14px;
  font-weight: 600;
}
.user-icon{
    background-color: #7d9ffc;
    padding: 15px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 800;
    color: #FFFFFF;
    cursor: pointer;
    margin: auto;
    flex-direction: column;
}
.user-name{
  font-size: 14px;
  color:#1B1C1D;
  font-weight: 600;
}
.user-email{
  font-size: 12px;
  color:#5E5F60;
  font-weight: normal;
}
::v-deep #checkbox-group-1 {
  display: flex;
  flex-direction: column;
}
::v-deep #checkbox-group-1 .custom-control-label span {
  position: relative;
  top: 5px;
}
</style>
