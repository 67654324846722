<template>
  <div class="invited_user">
    <div v-if="!isAddNewRole">
      <b-card class="m-2 mt-4" :body-class="'card-padding'" v-if="isUserFilter">
        <div class="row">
          <div class="col-md-3 input-user">
            <label class="tree-label">Search</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="searchValue"
              :labelStyle="'register-label'"
              :validation="{ alpha_num: true }"
              :placeholder="'Search Roles...'"
              @textInputChange="getSearchValue($event)"
            />
          </div>
          <!-- <div class="col-md-3">
            <label class="tree-label">Status</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="null"
              :selectOption="statusListOptions"
              @selectedOption="getStatusList($event)"
            />
          </div> -->
          <!-- <div class="col-md-3">
            <label class="tree-label">Role</label>
            <TreeDropdown
              :rootOptions="roleOptions"
              :selectedValue="roleSelected"
              @selectedField="getSelectedRoles($event)"
            />
          </div> -->
          <!-- <div class="col-md-3 input-user">
            <label class="tree-label">Manager</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="managerValue"
              :labelStyle="'register-label'"
              :validation="{ alpha_num: true }"
              :fieldName="'Manager'"
              @textInputChange="getManagerValue($event)"
            />
          </div> -->
        </div>
      </b-card>

      <w-table
        :tableProperties="tabledata"
        @getSelectedPageEvent="getSelectedPage($event)"
        @getSelectedPerPageEvent="getSelectedPerPage($event)"
        :isaddRole="true"
        @deleteRowDataEvent="openConfirmationModal($event)"
        @openModalAddRolePer="addNewRolesPermission($event)"
        @openRoleModalEvent="openRoleModalEvent($event)"
        :isFilterButton="true"
        @openDesktop_filterEvent="openDesktop_filter()"
      />

      <!-- delete role -->
      <w-delete-confirm-modal
        :msg="deleteRoleName"
        @deleteData="deleteRoles($event)"
      ></w-delete-confirm-modal>


    </div>
    <!-- modal Add permissions start-->
    <!-- <b-modal
      id="addRole-modal"
      :size="isSize"
      :title="title"
      centered
      :hideHeaderClose="true"
    >
      <div v-if="isNewRole">
        <h6 class="mx-3 my-3">Add New Role</h6>
        <div class="my-3 mx-3">
          <p class="mb-1" style="font-size:14px">Role Name</p>
          <div>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="roleName"
              :labelStyle="'register-label'"
              :validation="{ alpha_num: true }"
              :placeholder="'Enter Role...'"
              @textInputChange="getEnterRole($event)"
              :formatter="formatter"
            />
          </div>
        </div>
        <div class="footer-btn text-right my-3 mr-3">
          <span class="cancel-btn mr-3" @click="hidemodal">Cancel</span>
          <span class="submit-btn" @click="createRole">Save</span>
        </div>
      </div>
    </b-modal> -->
    <div v-if="isAddNewRole" class="row p-0 m-0">
      <div class="col-6 pl-0">
        <p v-if="isRoleName" class="roles-title">Add New Role</p>
        <p v-if="!isRoleName" class="roles-title">Permissions</p>
      </div>
      <div class="col-6 back-button" @click="backToRoles">
        <img src="../../assets/SVG/Back.svg" width="60px">
      </div>
      <w-roles
        class="col-12 p-0 m-0"
        v-if="isPermission"
        @closePermissionModal="backToRoles"
        @createRole="createRole($event)"
        :permissionExist="availPermission"
        :isRoleName="isRoleName"
        :roleName="roleName"
      ></w-roles>
    </div>
    <!-- Add permissions end-->

    <!-- popup for error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
// import Table from "../../widgets/Table.vue";
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import TreeDropdown from "../../widgets/TreeDropdown";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import DeleteConfirmModal from "../../widgets/DeleteConfirmModal.vue";
import Roles from "./Roles.vue";
import Modal from "../../widgets/ModalWidget";
import {
  getUserRoles,
  // getUserStatus,
  deleteRoles,
  addChangeRole,
  updatePermissions,
} from "../../Service/UserService";
export default {
  props:["isGetData"],
  components: {
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    TreeDropdown,
    "w-table": TableWithCheckbox,
    "w-delete-confirm-modal": DeleteConfirmModal,
    "w-roles": Roles,
    Modal,
  },

  created() {
    // this.getUserRoles();
    // // this.getUserStatus();
    if(this.isGetData){
      this.getData();
    }
  },

  data() {
    return {
      // for error modal
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      isNewRole: false,
      isPermission: false,
      isSize: "",
      title: "",

      deleteRoleName: "",
      deleteRoleId: "",

      // selectedStatusValue: "",
      // statusListOptions: [],

      // roleOptions: [],
      // roleSelected: [],

      // managerValue: "",
      searchValue: "",
      selectedRole: "",

      tabledata: {
        items: [],
        fields: [
          {
            key: "roleDisplayName",
            label: "Role Name",
          },
          {
            key: "role_permissions",
            label: "Permissions",
          },
          {
            key: "action_roleUser_field",
            label: "Action",
          },
        ],
        perPage: 50,
        currentPage: 0,
        totalRows: 3,
      },
      // permissionList:[],
      availPermission: [],
      roleDisplay: "",
      roleID: "",
      updatedPermission: "",
      isUserFilter:false,
      isAddNewRole:false,
      permissionSectionOption:[],
      isRoleName:true,
      roleName:""
    };
  },

  methods: {
    getData(){
      this.getUserRoles();
    },

    formatter(value) {
      return value.toUpperCase();
    },
    // status api call
    // getUserStatus() {
    //   this.statusListOptions = [];
    //   var status = [];
    //   this.statusListOptions.push({
    //     value: null,
    //     text: "Select Status",
    //   });
    //   getUserStatus()
    //     .then((res) => {
    //       status = res;
    //       status.map((element) => {
    //         this.statusListOptions.push({
    //           value: element,
    //           text: element,
    //         });
    //       });
    //     })
    //     .catch((err) => {
    //       this.alert = true;
    //       this.alertMsg = err.message;
    //       this.alertType = "Failure";
    //       this.isConfirmAlert = false;
    //     });
    // },

    getUserRoles() {
      getUserRoles()
        .then((res) => {
          if (res) {
            this.tabledata.items = res; // for roles table detail
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    openDesktop_filter(){
      this.isUserFilter = !this.isUserFilter
    },
    // for add role and permission
    addNewRolesPermission(data) {
      this.isAddNewRole = !this.isAddNewRole
      this.roleID = data.data.id;
      // this.roleDisplay = data.data.roleDisplayName;
      // if (data.name == "newRole") {
      //   this.isNewRole = true;
      //   this.isPermission = false;
      //   this.isSize = "";
      //   this.title = "Add New Role";
      // } else {
        if(data.name == "newRole"){
          this.isRoleName = true;
          this.roleName = "";
        }else{
          this.isRoleName = false
          this.roleName = data.data.roleDisplayName
        }
        this.isPermission = true;
        // this.isNewRole = false;
        // this.isSize = "xl";
        // this.title = "";
        console.log(data," data")
        this.availPermission = [];
        data.data.permissions.forEach((el) => {
          this.availPermission.push(el.permissionName);
        });

      // }
      // this.$bvModal.show("addRole-modal");

    },

// Add new Role
    createRole(data) {
      if(this.isRoleName){
        addChangeRole(data)
        .then((res) => {
          setTimeout(this.$bvModal.hide("addRole-modal"), 1000);
          this.roleName="";
          this.getUserRoles();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
      }else{
        this.updatedPermission = {
          permissions: data.permissions,
          roleDisplayName: data.roleDisplayName,
          roleId: this.roleID,
        };
        updatePermissions(this.updatedPermission).then((res) => {
            this.alert = false;
            this.getUserRoles();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
      }
      this.backToRoles();
    },

    hidemodal() {
      this.$bvModal.hide("addRole-modal");
    },
    savePermissions(permissions) {
      this.alertMsg = "Are you sure want to Add Permissions ?";
      this.alert = true;
      this.alertType = "";
      this.isConfirmAlert = true;

      this.updatedPermission = {
        permissions: permissions,
        roleDisplayName: this.roleDisplay,
        roleId: this.roleID,
      };

      // this.$bvModal.hide("addRole-modal");
    },

    updatePermissions() {
      updatePermissions(this.updatedPermission)
        .then((res) => {
          this.alert = false;
          this.getUserRoles();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    confirmOk() {
      this.updatePermissions();
    },

    // delete role start
    deleteRoles(isDelete) {
      if (isDelete) {
        deleteRoles(this.deleteRoleId)
          .then((res) => {
            this.getUserRoles();
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      }
      this.$bvModal.hide("delete-confirm-pop-modal");
    },

    openConfirmationModal(data) {
      this.$bvModal.show("delete-confirm-pop-modal");
      this.deleteRoleName = data.roleDisplayName;
      this.deleteRoleId = data.id;
    },
    backToRoles(){
      this.isAddNewRole = !this.isAddNewRole
      this.isPermission = !this.isPermission
    },
    // get search input value
    getSearchValue(event) {
      this.searchValue = event;
    },
    // get entered role
    getEnterRole(event) {
      this.roleName = event;
    },
    // selected status
    // getStatusList(event) {
    //   this.selectedStatusValue = event;
    // },

    // getSelectedRoles(event) {
    //   this.roleSelected = event;
    // },

    // error popup close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>

<style scoped>
/* .card-padding {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0 !important;
} */
.invited_user .filteruser .filter {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #1b1c1d;
  font-weight: 600;
}
.invited_user .filteruser label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #767676;
}
.invited_user .input-user ::v-deep .form-control {
  height: 35px !important;
  opacity: 0.7;
}
.pagination_user ul {
  margin-bottom: 0;
}
.pagination_user .show-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #222838;
}
.adduser_btn {
  border: solid 1px #5fa6e7;
  border-radius: 2px;
  color: #2e8cd3;
  font-size: 13px;
  padding: 2px 10px;
  background-color: #fff;
}
.adduser_btn:hover {
  background: #fff;
}
.userIcon {
  width: 18px;
  height: 10px;
  object-fit: contain;
  display: inline-block;
  margin: 3px;
}
.roles-title{
  font-size: 16px;
  color:#4A4A4A;
  font-weight: bold;
}
.invited_user .filteruser ::v-deep .card-body {
  padding: 14px !important;
}
.invited_user ::v-deep table thead th {
  color: #1b1c1d;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  text-align: center;
}
.invited_user ::v-deep table tbody td {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  color: #1b1c1d;
  text-align: center;
}
.invited_user ::v-deep table thead th:first-child {
  text-align: left;
}
.invited_user ::v-deep table tbody td:first-child {
  text-align: left;
}

/* modal buttons */
.cancel-btn,
.submit-btn {
  border: 1px solid #2e8cd3;
  padding: 4px 15px;
  color: #2e8cd3;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}
.submit-btn {
  background: #2e8cd3;
  color: #fff;
}
.invited_user ::v-deep #app {
  display: flex;
}
::v-deep .modal-header {
  display: none !important;
}
.back-button{
    text-align: right;
    color:#2E8CD3;
    font-size: 14px;
    cursor: pointer;
} 

@media screen and (max-width:768px) {
  .invited_user{
    margin: 20px 0;
  }
}
</style>
