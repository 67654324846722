<template>
  <div>
    <div>
    <b-tabs content-class="mt-0" ref="tabs" class="report-tabs" v-model="tabIndex" align="center">
      <b-tab title="USERS" class="stock " @click="getActiveTab('users')" v-if="permission[0].isFirstPermission || permission[0].isSecondPermission" active>
        <ViewUser ref="userTab" :isGetData="getDataTab === 'users'" />
      </b-tab>
      <b-tab title="ROLES" class="listing" @click="getActiveTab('roles')" v-if="permission[1].isFirstPermission || permission[1].isSecondPermission">
         <NewRoles ref="roleTab" :isGetData="getDataTab === 'roles'" />
      </b-tab>
    </b-tabs>
    </div>
  </div>
</template>
<script>
import ViewUser from "../components/User/ViewUser.vue";
import NewRoles from "../components/User/NewRoles.vue";
import { isAuthorized } from '../Service/CommonService';

export default {
  components: {
    ViewUser,
    NewRoles
  },
  data() {
    return {
      tabIndex: 0,
      getDataTab:"",
      permission:[
        {
          title:'users',
          isFirstPermission:isAuthorized('allow_view_users'),
          isSecondPermission:isAuthorized('allow_manage_own_company'),
        },
        {
          title:'roles',
          isFirstPermission:isAuthorized('allow_view_roles'),
          isSecondPermission:isAuthorized('allow_manage_own_company'),
        }
      ]
    };
  },
  created(){
    if(isAuthorized('allow_view_users') || isAuthorized('allow_manage_own_company')){
      this.getDataTab = "users"
    }else if(isAuthorized('allow_view_roles') || isAuthorized('allow_manage_own_company')){
      this.getDataTab = "roles"
    }
  },
  methods:{
    getActiveTab(tabName){
      this.activeTab = tabName;
      if(this.activeTab === "users"){
        this.$refs.userTab.getData();
      }
      else if(this.activeTab === "roles"){
        this.$refs.roleTab.getData();
      }
    }
  }
};
</script>

<style  scoped>
@media screen and (max-width:768px) {
  .report-tabs ::v-deep .nav{
    justify-content: center !important;
  }  
}

</style>